@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  html {
    @apply antialiased;
  }

  h1,
  h2,
  h3,
  h4 {
    @apply font-sans font-bold text-click-blue tracking-[-0.02em];
  }

  h1 {
    @apply text-3xl lg:text-4xl mb-4;
  }

  h2 {
    @apply text-2xl lg:text-3xl mb-4;
  }

  h3 {
    @apply text-xl lg:text-2xl mb-4;
  }

  h4 {
    @apply text-xl mb-4;
  }

  p {
    @apply mb-4 text-click-text;
  }

  .copy h2 {
    @apply text-2xl lg:text-3xl font-bold text-click-blue mb-4;
  }

  .copy p {
    @apply text-base text-click-text mb-4;
  }

  .copy a {
    @apply text-click-orange hover:text-click-blue transition;
  }

  .copy strong {
    @apply font-semibold;
  }

  .copy ul {
    @apply list-disc list-outside mb-4;
  }

  .copy li {
    @apply text-base text-click-text mb-2 ml-6;
  }

  .copy blockquote {
    @apply border-l-[5px]	border-click-orange bg-click-grey p-6 rounded-md mb-sm;
  }

  .copy blockquote p {
    @apply text-click-blue;
  }

  .copy hr {
    @apply my-8;
  }

  .copy img {
    @apply mb-4;
  }

  .copy-dark h2 {
    @apply text-2xl lg:text-3xl font-bold text-white mb-4;
  }

  .copy-dark p {
    @apply text-base text-white text-opacity-80 mb-4;
  }

  .copy-dark a {
    @apply text-click-orange hover:text-click-orange-light text-opacity-100 transition;
  }

  .copy-dark strong {
    @apply font-semibold;
  }

  .copy-dark ul {
    @apply list-disc list-outside mb-4;
  }

  .copy-dark li {
    @apply text-base text-white text-opacity-80 mb-2 ml-6;
  }

  .copy-dark blockquote {
    @apply border-l-[5px]	border-click-orange bg-white bg-opacity-5 p-6 rounded-md mb-sm;
  }

  .copy-dark blockquote p {
    @apply text-white text-opacity-80;
  }

  .copy-dark hr {
    @apply my-8;
  }

  .copy-dark img {
    @apply mb-4;
  }

  .table {
    @apply block table-auto w-full text-sm text-click-para ring-1 ring-gray-200 rounded-lg overflow-hidden;
  }

  .table table {
    width: 100%;
  }

  .table thead {
    @apply bg-click-grey text-click-blue text-left;
  }

  .table th {
    @apply p-3 md:p-4;
  }

  .table tr {
    @apply w-full border-b border-gray-200 border-dashed last-of-type:border-none;
  }

  .table td {
    @apply p-3 md:p-4;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .bg-click-curve-orange:before,
  .bg-click-curve-manage:before,
  .bg-click-curve-digital:before,
  .bg-click-curve-marketing:before {
    left: 20%;
    width: 2000px;
    top: 70%;
    height: 2000px;
    content: '';
    z-index: 0;
    position: absolute;
    transform: rotate(-30deg);
    border-radius: 66px;
    transform-origin: top left;
  }

  .bg-click-curve-orange:after,
  .bg-click-curve-manage:after,
  .bg-click-curve-digital:after,
  .bg-click-curve-marketing:after {
    /* left: 100%; */
    width: 2000px;
    top: 90%;
    left: 75%;
    height: 2000px;
    content: '';
    z-index: 0;
    position: absolute;
    transform: rotate(-30deg);
    border-radius: 66px;
    transform-origin: top left;
  }

  .bg-click-curve-orange:before {
    @apply bg-gradient-to-b from-click-orange to-[#E64213];
  }

  .bg-click-curve-orange:after {
    @apply bg-gradient-to-tr from-[#0091D1] to-click-blue;
  }

  .bg-click-curve-manage:before {
    @apply bg-gradient-to-b from-click-manage to-[#276390];
  }

  .bg-click-curve-manage:after {
    @apply bg-gradient-to-tr from-[#0091D1] to-click-blue;
  }

  .bg-click-curve-digital:before {
    @apply bg-gradient-to-b from-click-digital to-[#891D62];
  }

  .bg-click-curve-digital:after {
    @apply bg-gradient-to-tr from-[#0091D1] to-click-blue;
  }

  .bg-click-curve-marketing:before {
    @apply bg-gradient-to-b from-click-marketing to-[#69A846];
  }

  .bg-click-curve-marketing:after {
    @apply bg-gradient-to-tr from-[#0091D1] to-click-blue;
  }
}

.form-select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' fill='currentColor'%3E%3Cpath d='M201.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 338.7 54.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z'/%3E%3C/svg%3E");
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  background-repeat: no-repeat;
}
